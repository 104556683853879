import {AuthType, CommunicationLanguage, FiltersBehavior} from "@/models/Enums";
import {GeoLocation} from "@renta-apps/athenaeum-toolkit";
import {ch} from "@renta-apps/athenaeum-react-common";

export default class SaveUserRequest {
    public id: string | null = null;

    public authType: AuthType = AuthType.Email;

    public email: string = "";

    public phone: string = "";

    public firstname: string = "";

    public middleName: string = "";

    public lastName: string = "";

    public language: string = "";

    public roleName: string = "";

    /**
     * ERP Id of the User.
     */
    public externalId: string | null = null;

    public organizationContractIds: string[] = [];

    public filtersBehavior: FiltersBehavior = FiltersBehavior.SystemDefault;

    public location: GeoLocation | null = null;

    public employeeNumber: string = "";

    public agreementAccepted: boolean = false;

    public registrationAccepted: boolean = false;

    public useConstructionSiteCommunicationLanguage: boolean = false;

    public communicationLanguage: CommunicationLanguage = ch.isFinland ? CommunicationLanguage.Finnish : CommunicationLanguage.Swedish;

    public warehouseId: string | null = null;

    public costPoolIds: string[] = [];

    /**
     * Subcontractor mounter permission.
     */
    public canCreateWorkOrder: boolean = false;

    /**
     * Subcontractor mounter permission.
     */
    public canCompleteWorkOrder: boolean = false;

    /**
     * Subcontractor mounter permission.
     */
    public canProcessForm: boolean = false;
}