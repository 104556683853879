import {CommunicationLanguage} from "@/models/Enums";
import {ch} from "@renta-apps/athenaeum-react-common";

export class SendWorkOrderExternalNotificationRequest {
    
    public workOrderId: string = "";

    public email: string = "";

    public comment: string = "";

    public userId: string = "";

    public requestDate: Date = new Date();
    
    public communicationLanguage: CommunicationLanguage = ch.isFinland ? CommunicationLanguage.Finnish : CommunicationLanguage.Swedish;

    public selectedMountersIds: string[] = [];
}