import React from "react";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import {ITimelineRow, PageContainer, PageHeader} from "@renta-apps/athenaeum-react-components";
import {PageCacheProvider, PageRouteProvider} from "@renta-apps/athenaeum-react-common";
import CalendarToolbar from "@/pages/Calendar/Toolbar/CalendarToolbar";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import ConstructionSite from "@/models/server/ConstructionSite";
import BaseCalendarDataRequest from "@/pages/Calendar/BaseCalendarDataRequest";
import CalendarData from "@/pages/Calendar/CalendarData/CalendarData";
import WorkOrderModel from "@/models/server/WorkOrderModel";
import {CalendarUnitTime} from "@/models/Enums";
import GetCalendarWorkOrdersRequest from "@/models/server/requests/GetCalendarWorkOrdersRequest";
import CalendarFiltersData from "./Toolbar/CalendarFiltersData";
import PageDefinitions from "@/providers/PageDefinitions";
import User from "@/models/server/User";
import UpdateMountersAndTimesRequest from "@/models/server/requests/UpdateMountersAndTimesRequest";
import UserInteractionDataStorage from "@/providers/UserInteractionDataStorage";
import Localizer from "../../localization/Localizer";
import UnleashHelper from "@/helpers/UnleashHelper";
import FeatureFlags from "@/helpers/FeatureFlags";
import Subcontractor from "@/models/server/Subcontractor";

import styles from "./Calendar.module.scss";
import "./BootstrapOverride.scss";

interface ICalendarProps {
}

interface ICalendarState {
    filtersData: CalendarFiltersData;
    request: BaseCalendarDataRequest;
    constructionSites: ConstructionSite[];
    tasks: WorkOrderModel[];
    isTeams: boolean;
    rows: readonly Readonly<ITimelineRow>[]
    startDate: Date;
    endDate: Date;

    /** All (non-deleted) sub contractors. */
    subContractors: Subcontractor[];
    // selectedTeamsIds: string[];
    // teams: Team[];
}

export default class Calendar extends AuthorizedPage<ICalendarProps, ICalendarState> {

    // Fields

    public state: ICalendarState = {
        filtersData: new CalendarFiltersData(),
        request: new BaseCalendarDataRequest(),
        constructionSites: [],
        tasks: [],
        isTeams: false,
        startDate: Utility.getFirstDayOfWeek(),
        endDate: Utility.getLastDayOfWeek(),
        rows: [],
        subContractors: [],
        // selectedTeamsIds: [],
        // teams: [],
    };

    private readonly _calendarDataRef: React.RefObject<CalendarData> = React.createRef();

    public static readonly snapSeconds: number = 60 * 60;
    public static readonly snapSecondsDailyView: number = 60 * 15;

    /**
     *  Note: update storage id's version number if stored model has new fields or other changes.
     *  E.g. new fields will be received as undefined for existing values from storage.
     */
    private readonly _requestStorageKey: string = "task.request.v3";

    // Properties

    private get request(): BaseCalendarDataRequest {
        return this.state.request;
    }

    //
    // private get mountersList(): List {
    //     return this._mountersRef.current!;
    // }

    private get filtersData(): CalendarFiltersData {
        return this.state.filtersData;
    }

    private get tasks(): WorkOrderModel[] {
        return this.state.tasks;
    }

    private get mounters(): User[] {
        return this.filtersData.mounters;
    }

    private get selectedMounters(): User[] {
        return this.request.mounters;
    }

    // private get selectedTeamsIds(): string[] {
    //     return this.state.selectedTeamsIds;
    // }
    //
    // private get constructionSites(): ConstructionSite[] {
    //     return this.state.constructionSites;
    // }

    // private get teams(): Team[] {
    //     return this.state.teams;
    // }

    private get selectedCalendarTimeUnit(): CalendarUnitTime {
        return this.request.calendarUnitType ?? CalendarUnitTime.Week;
    }

    private get startDate(): Date {
        return this.state.startDate;
    }

    private get endDate(): Date {
        return this.state.endDate;
    }
    
    private get snapSeconds(): number {
        return this.selectedCalendarTimeUnit === CalendarUnitTime.Day ? Calendar.snapSecondsDailyView : Calendar.snapSeconds;
    }

    // Methods

    // private async toggleTabAsync(): Promise<void> {
    //     const isTeams: boolean = !this.isTeams;
    //
    //     const selectedTeamsIds: string[] = isTeams ? UserInteractionDataStorage.get("task.selectedTeamsIds", []) : [];
    //     const selectedMounters: TaskMounter[] = !isTeams ? UserInteractionDataStorage.get("task.selectedMounters", []) : [];
    //
    //     await this.setState({
    //         isTeams,
    //         selectedMounters: selectedMounters,
    //         selectedTeamsIds: selectedTeamsIds});
    //
    //     await this.getTasksAsync();
    // }

    private async getTasksAsync(): Promise<void> {
        const request: GetCalendarWorkOrdersRequest = new GetCalendarWorkOrdersRequest();

        // request.teamsIds = this.selectedTeamsIds;
        request.warehouseIds = this.request.warehouses.map(warehouse => warehouse.id);
        request.constructionSiteIds = this.request.constructionSites.map(constructionSite => constructionSite.id);
        request.workOrderStatuses = this.request.statuses;
        request.mounterCostPoolIds = UnleashHelper.isEnabled(FeatureFlags.CostPoolMounterAutoSelect)
            ? [] // Use only mounter filter
            :this.request.mounterCostPools.map(costPool => costPool.id);
        request.search = this.request.search;
        request.mounterIds = this.request.mounters.map(user => user.id);
        request.managerIds = this.request.managers.map(user => user.id);
        request.viewStart = this.startDate;
        request.viewEnd = this.endDate;

        const tasks: WorkOrderModel[] = await this.postAsync("api/calendar/getWorkOrders", request);
        await this.setState({tasks});
    }

    // private async getConstructionSitesAsync(): Promise<void> {
        // const request = new GetCalendarConstructionSitesRequest();
        // request.mounterUserIds = this.selectedMountersIds;
        // request.warehouseIds = this.request.warehouses.map(warehouse => warehouse.id);
        // request.constructionSiteIds = this.request.constructionSites.map(constructionSite => constructionSite.id);
        //
        // const constructionSites: ConstructionSite[] = await this.postAsync("api/calendar/getCalendarConstructionSites", request);
        //
        // await this.setState({constructionSites});
    // }



    // private async getTeamsAsync(sender: IBaseComponent): Promise<Team[]> {
    //     const teams: Team[] = await PageCacheProvider.getAsync("getTeamsAsync", () => sender.postAsync("api/team/getTeams", null));
    //
    //     await this.setState({teams});
    //
    //     return teams;
    // }

    // private async reloadTeamsAsync(): Promise<void> {
    //     // Clear cache to get fresh team and mounter data
    //     PageCacheProvider.clear();
    //
    //     if (this._teamsRef.current) {
    //         // Reload teams to get updated team members.
    //         await this._teamsRef.current!.reloadAsync();
    //     }
    //
    //     // Reload mounters to get updated team data related to users.
    //     await this.getMountersAndSetStateAsync(this);
    //
    //     // Reload tasks to get all tasks of newly added team members.
    //     await this.getTasksAsync();
    // }

    // private async openTeamModalAsync(team: Team): Promise<void> {
    //     await this._teamModalRef.current!.openAsync(team);
    // }

    private async changeDataAsync(type: "prev" | "next" | "today"): Promise<void> {
        let startDate: Date | null = null;
        let endDate: Date | null = null;
        switch (type) {
            case "prev":
                if (this.selectedCalendarTimeUnit === CalendarUnitTime.Week) {
                    startDate = this.startDate.addDays(-7);
                    endDate = this.endDate.addDays(-7);
                } else if (this.selectedCalendarTimeUnit === CalendarUnitTime.Month) {
                    startDate = this.startDate.addMonths(-1).getFirstDayOfMonth();
                    endDate = this.endDate.addMonths(-2).getLastDayOfMonth().addDays(1);
                } else if (this.selectedCalendarTimeUnit === CalendarUnitTime.Day) {
                    startDate = this.startDate.addDays(-1);
                    endDate = this.endDate.addDays(-1);
                }
                break;
            case "next":
                if (this.selectedCalendarTimeUnit === CalendarUnitTime.Week) {
                    startDate = this.startDate.addDays(7);
                    endDate = this.endDate.addDays(7);
                } else if (this.selectedCalendarTimeUnit === CalendarUnitTime.Month) {
                    startDate = this.startDate.addMonths(1).getFirstDayOfMonth();
                    endDate = this.endDate.getLastDayOfMonth().addDays(1);
                } else if (this.selectedCalendarTimeUnit === CalendarUnitTime.Day) {
                    startDate = this.startDate.addDays(1);
                    endDate = this.endDate.addDays(1);
                }
                break;
            case "today":
                if (this.selectedCalendarTimeUnit === CalendarUnitTime.Week) {
                    startDate = Utility.getFirstDayOfWeek();
                    endDate = Utility.getLastDayOfWeek().addDays(1);
                } else if (this.selectedCalendarTimeUnit === CalendarUnitTime.Month) {
                    startDate = Utility.getFirstDayOfMonth();
                    endDate = Utility.getLastDayOfMonth().addDays(1);
                } else if (this.selectedCalendarTimeUnit === CalendarUnitTime.Day) {
                    startDate = Utility.today();
                    endDate = Utility.today().addDays(1);
                    endDate.setHours(endDate.getHours() + 8);
                }
                break;
        }
        
        if (!startDate || !endDate) {
            throw new Error("Configuration missing for time unit " + this.selectedCalendarTimeUnit);
        }
        
        await this.setState({
            startDate: startDate!,
            endDate: endDate!,
        });
    }

    // private async getConstructionSiteAgreements(_: IBaseComponent, constructionSiteId: string): Promise<ConstructionSiteAgreement[]> {
    //     const constructionSite: ConstructionSite = this
    //         .constructionSites
    //         .find(constructionSite => (constructionSite.id === constructionSiteId))!;
    //
    //     return constructionSite.agreements || [];
    // }

    // private async openAddTaskModalAsync(task: TaskModel | null = null) {
    //     if (!task) {
    //         await this._addTaskModalRef.current!.openAsync(null);
    //     } else {
    //         await this._addTaskModalRef.current!.openAsync(task);
    //     }
    // }

    // private async selectTeamsAsync(sender: List<Team>): Promise<void> {
    //     const selectedTeams: Team[] = sender.selectedItems;
    //     const selectedTeamsIds: string[] = selectedTeams.map(team => team.id);
    //
    //     UserInteractionDataStorage.set("task.selectedTeamsIds", selectedTeamsIds);
    //     await this.setState({selectedTeamsIds});
    //     await this.getTasksAsync();
    // }

    private async onFiltersChangeAsync(request: BaseCalendarDataRequest, type: "prev" | "next" | "today" | null): Promise<void> {
        UserInteractionDataStorage.setFilters(request, this._requestStorageKey);

        await this.setState({request});
        if (type) {
            await this.changeDataAsync(type);
        }

        await this.getTasksAsync();
        
        // if (reloadMounters && this._teamsRef.current) {
        //     await this._teamsRef.current!.reloadAsync();
        // }
    }

    // private async addTaskAsync(_: IBaseComponent, request: CreateTaskRequest): Promise<CreateTaskResponse> {
    //     // const response = await RentaTasksController.createTaskAsync(request);
    //     //
    //     // if (response.success) {
    //     //     await this.getTasksAsync();
    //     // }
    //     //
    //     // return response;
    // }

    // private async saveTasksAsync(tasks: TaskModel[]): Promise<void> {
    //     const promises: Promise<[boolean, TaskModel]>[] = tasks.map(task => this.saveTaskAsync(task, "save", false));
    //
    //     await Promise.all(promises);
    //
    //     await this.getTasksAsync();
    // }
    //
    // private async saveTaskAsync(model: TaskModel, actionName: string, reload: boolean): Promise<[boolean, TaskModel]> {
    //
    //     const actionResult: [boolean, TaskModel] = await RentaManagementController.handleTaskActionAsync(model, actionName);
    //     const actionSucceeded: boolean = actionResult[0];
    //
    //     if ((reload) && (actionSucceeded)) {
    //         await this.getTasksAsync();
    //     }
    //
    //     return actionResult;
    // }
    //
    // private closeNewAddTaskModalAsync = async (): Promise<void> => {
    //     await this.getTasksAsync();
    // };

    private async refreshFilterData(): Promise<void> {
        const filters: CalendarFiltersData = await PageCacheProvider.getAsync("getCalendarFiltersData",
            () => this.postAsync("api/calendar/getCalendarFiltersData"));

        await this.setState({filtersData: filters});
    }

    private readonly onWorkOrderChangeAsync = async (request: UpdateMountersAndTimesRequest, subContractorChanged: boolean): Promise<void> => {
        await this.postAsync("api/calendar/updateMountersAndTimes", request);
        await this.getTasksAsync();

        if (subContractorChanged) {
            const subContractors: Subcontractor[] = await this.postAsync("api/calendar/getAllSubContractors");
            await this.setState({subContractors: subContractors});
        }
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const request: BaseCalendarDataRequest = UserInteractionDataStorage.getFilters(new BaseCalendarDataRequest(), this._requestStorageKey)
        const subContractors: Subcontractor[] = await this.postAsync("api/calendar/getAllSubContractors");
        
        await this.setState({
            request: request,
            subContractors: subContractors,
        });

        await this.refreshFilterData();
        await this.getTasksAsync();
    }

    public render(): React.ReactNode {
        return (
            <PageContainer scale
                           className={styles.calendar}
            >
                <PageHeader className={styles.pageHeader}
                            title={Localizer.calendarPageTitle}
                />

                <div className={styles.container}>

                    <div id="calendarPanelRight"
                         className={styles.rightPanel}>
                        <div>
                            <CalendarToolbar filtersData={this.filtersData}
                                             request={this.request}
                                             startDate={this.startDate}
                                             selectedCalendarTimeUnit={this.selectedCalendarTimeUnit}
                                             onAddNewWorkOrder={async () => {await PageRouteProvider.redirectAsync(PageDefinitions.workOrderRoute())}}
                                             onChange={(request: BaseCalendarDataRequest, type: "prev" | "next" | "today" | null) => this.onFiltersChangeAsync(request, type)}
                                             // openTeamModal={async (_, team: Team) => await this.openTeamModalAsync(team)}
                                             // openAddTaskModal={async () => this.openAddTaskModalAsync()}
                            />

                            <CalendarData id={"calendarData"}
                                          ref={this._calendarDataRef}
                                          tasks={this.tasks}
                                          mounters={this.mounters}
                                          selectedMounters={this.selectedMounters}
                                          startDate={this.startDate}
                                          endDate={this.endDate}
                                          onWorkOrderChange={this.onWorkOrderChangeAsync}
                                          subContractors={this.state.subContractors}
                                          snapSeconds={this.snapSeconds}
                                          // teams={this.teams}
                                          // selectedTeamIds={this.state.selectedTeamsIds}
                                          // openAddTaskModal={this.openAddTaskModalAsync.bind(this)}
                                          // saveTasksAsync={this.saveTasksAsync.bind(this)}
                            />
                        </div>
                    </div>
                </div>
            </PageContainer>
        );
    }
}