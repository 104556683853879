/**
 *  Keys for feature flags. There's a matching .cs file.
 */
export default class FeatureFlags {

    /**
     * Enables calendar planner in application UI
     */
    public static readonly Calendar = "CALENDAR" as const;

    /**
     * Controls Admin CRUD view of extra charge types, and adding extra charges on a work order
     */
    public static readonly ExtraCharge = "EXTRA_CHARGE" as const;

    /**
     * Enables Sync service
     */
    public static readonly Sync = "SYNC" as const;

    /**
     * Enables displaying, sorting and searching by project number in construction sites
     */
    public static readonly ProjectNumberInConstructionSites = "PROJECT_NUMBER_IN_CONSTRUCTION_SITES" as const;

    /**
     * Enables displaying and modification of users employee number in UserManagement page.
     */
    public static readonly EmployeeNumber = "EMPLOYEE_NUMBER" as const;

    /**
     * Enables option to add rental equipment to work order.
     */
    public static readonly RentalEquipment = "RENTAL_EQUIPMENT" as const;

    /**
     * Enables new full-text-search functionality for construction site page.
     */
    public static readonly ConstructionSiteFullTextSearch = "CS_FULLTEXT_SEARCH" as const;

    /**
     * Enables MySqlMatchSearchMode.Boolean for {@see ConstructionSiteFullTextSearch} 
     */
    public static readonly ConstructionSiteFullTextSearchBooleanMode = "CS_FULLTEXT_SEARCH_BOOLEAN_MODE" as const;

    /**
     * Enables and displays alarm job functions on the UI.
     */
    public static readonly AlarmJobs = "ALARM_JOBS" as const;

    /**
     * Enables and displays comment field for user salary hours on the UI.
     */
    public static readonly UserSalaryHoursComment = "HOURS_COMMENT" as const;

    /**
     * Displays rental mass products on the catalog and on work order pages.
     */
    public static readonly RentalMassProducts = "RENTAL_MASS_PRODUCTS" as const;

    /**
     * If enabled when a cost pool is selected from a dropdown in the related mounter dropdown all mounters
     * who have that cost pool set will be automatically selected.
     */
    public static readonly CostPoolMounterAutoSelect = "COST_POOL_MOUNTER_AUTO_SELECT" as const;

    /**
     * Enables sending work order notifications to external personnel
     */
    public static readonly ExternalNotifications = "EXTERNAL_NOTIFICATIONS" as const;

    /**
     * If enabled, it will be possible to assign a cost pool to the work order
     */
    public static readonly WorkOrderCostPool: string = "WORK_ORDER_COST_POOL";

    /**
     * If enabled, will display a "debug" view of organization contract external ids in user management for contact people.
     */
    public static readonly ShowContactPersonExternalIds: string = "SHOW_CONTACT_PERSON_EXTERNAL_IDS" as const;

    /**
     * If enabled, construction sites  are integrated from Task to ERP.
     */
    public static readonly ConstructionSiteIntegration: string = "CONSTRUCTION_SITE_INTEGRATION" as const;

    /// <summary>
    /// If enabled, create order request will be sent upon work order invoicing.
    /// </summary>
    public static readonly SendCreateOrderRequest: string = "SEND_CREATE_ORDER_REQUEST" as const;

    /**
     * If enabled, work order's date/time fields will have 15 minute accuracy instead of one hour.
     */
    public static readonly WorkOrder15MinuteAccuracy: string = "WORK_ORDER_15_MINUTE_ACCURACY" as const;

    /**
     * If enabled, users can log in using Azure Active Directory single sign on.
     */
    public static readonly AzureAdSso: string = "AZURE_AD_SSO" as const;

    /**
     * If enabled, users can get a work report with comments.
     * Typically, this will be enabled together with
     * @see UserSalaryHoursComment
     */
    public static readonly WorkReportWithComments: string = "WORK_REPORT_WITH_COMMENTS" as const;
}