import React, {createRef} from "react";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import {
    Button,
    ButtonType,
    Checkbox,
    DateInput,
    Dropdown,
    DropdownAlign,
    DropdownOrderBy,
    Form,
    Icon,
    IconSize,
    IconStyle,
    Inline,
    JustifyContent,
    SelectListItem,
    ToolbarButton,
    ToolbarContainer,
    ToolbarRow
} from "@renta-apps/athenaeum-react-components";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "@/localization/Localizer";
import CostPool from "@/models/server/CostPool";
import WorkOrderSearchInput from "@/components/WorkOrderSearchInput/WorkOrderSearchInput";
import WorkOrdersToolbarModel from "@/pages/WorkOrders/WorkOrdersToolbar/WorkOrdersToolbarModel";
import User from "@/models/server/User";
import Debouncer from "@/helpers/Debouncer";
import UnleashHelper from "@/helpers/UnleashHelper";
import FeatureFlags from "@/helpers/FeatureFlags";
import {FeatureSwitch} from "@/components/FeatureSwitch/FeatureSwitch";

import styles from "./WorkOrdersToolbar.module.scss";

/**
 * Props for {@link WorkOrdersToolbar}.
 */
export interface IWorkOrdersToolbarProps {
    model: WorkOrdersToolbarModel;

    /**
     * If defined, {@link costPools} is required.
     */
    costPoolFilterEnabled: boolean;

    /**
     * Manager users displayed in a filter dropdown.
     */
    managers: readonly Readonly<User>[];

    /**
     * Mounter users displayed in a filter dropdown.
     */
    mounters: readonly Readonly<User>[];

    addWorkOrder(): Promise<void>;

    /**
     * Invoked when the {@link WorkOrdersToolbar} model changes.
     *
     * @param model The changed {@link WorkOrdersToolbar} model.
     */
    onChange(model: WorkOrdersToolbarModel): Promise<void>;

    /**
     * Cost pools displayed in a filter dropdown. Required if {@link costPoolFilterEnabled} is true.
     */
    costPools?: CostPool[];

    /**
     * Called when user clicks on "get bulk PDF" button.
     */
    onGetBulkPdf?(model: WorkOrdersToolbarModel): Promise<void>;
    
    readonly?: boolean;
}

/** @private */
interface IWorkOrdersToolbarState {
    model: WorkOrdersToolbarModel;
}

/**
 * {@link WorkOrders} page toolbar.
 */
export default class WorkOrdersToolbar extends BaseComponent<IWorkOrdersToolbarProps, IWorkOrdersToolbarState> {

    public state: IWorkOrdersToolbarState = {
        model: this.props.model
    };

    private readonly _searchInputRef: React.RefObject<WorkOrderSearchInput> = createRef();
    private readonly _managersDropdownRef: React.RefObject<Dropdown<User>> = createRef();
    private readonly _mountersDropdownRef: React.RefObject<Dropdown<User>> = createRef();
    private readonly _searchDebouncer: Debouncer = new Debouncer();

    private get readonly(): boolean {
        return (!!this.props.readonly);
    }
    private async processOnChange(invoke: boolean = false): Promise<void> {
        await this.setState({
            model: this.state.model,
        });

        // Required to reset Dropdown selected items state
        this._managersDropdownRef.current!.reRenderAsync();
        this._mountersDropdownRef.current!.reRenderAsync();

        if (invoke) {
            await this.props.onChange(this.state.model);
        }
    }

    private async setFromAsync(from: Date | null): Promise<void> {
        if (this.state.model.from !== from) {
            this.state.model.from = from;
            await this.processOnChange();
        }
    }

    private async setToAsync(to: Date | null): Promise<void> {
        if (this.state.model.to !== to) {
            this.state.model.to = to;
            await this.processOnChange();
        }
    }

    private onSearchChangeAsync = async (search: string): Promise<void> => {
        if (this.state.model.search !== search) {
            this.state.model.search = search;

            this._searchDebouncer.debounce(
                async () => {
                    await this.processOnChange(true);
                },
                500
            );
        }
    }

    private async selectTaskStatusesAsync(items: SelectListItem[], userInteraction: boolean): Promise<void> {

        this.state.model.taskStatusesFilter = (items != null && items.length > 0)
            ? items.map(item => parseInt(item.value))
            : [];

        if (userInteraction) {
            await this.processOnChange(true);
        }
    }

    private async selectTaskCostPoolsAsync(costPools: CostPool[], userInteraction: boolean): Promise<void> {

        this.state.model.managerCostPoolsFilter = costPools;

        if (UnleashHelper.isEnabled(FeatureFlags.CostPoolMounterAutoSelect)) {
            this.state.model.mounters = User.getWithCostPools([...this.props.mounters], costPools);
        }

        if (userInteraction) {
            await this.processOnChange(true);
        }
    }

    private readonly onManagersChangeAsync = async (dropdown: Dropdown<User>, _: User | null, userInteraction: boolean): Promise<void> => {
        this.state.model.managers = dropdown.selectedItems;

        if (userInteraction) {
            await this.processOnChange(true);
        }
    };

    private readonly onMountersChangeAsync = async (dropdown: Dropdown<User>, _: User | null, userInteraction: boolean): Promise<void> => {
        this.state.model.mounters = dropdown.selectedItems;

        if (this.state.model.mounters.length > 0) {
            // Reset "notAssigned" if it was selected.
            this.state.model.notAssigned = false;
        }

        if (userInteraction) {
            await this.processOnChange(true);
        }
    };

    private readonly onNotAssignedChangeAsync = async (_: Checkbox, notAssigned: boolean): Promise<void> => {
        if (this.state.model.notAssigned !== notAssigned) {
            this.state.model.notAssigned = notAssigned;

            if (notAssigned) {
                this.state.model.mounters = [];
            }

            await this.processOnChange(true);
        }
    };

    private getTaskStatusFilterSelectedItems(): SelectListItem[] {
        return (this.state.model.taskStatusesFilter != null && this.state.model.taskStatusesFilter.length > 0)
            ? this.state.model.taskStatusesFilter.map(item => EnumProvider.getTaskStatusFilterItem(item))
            : [];
    }

    private async clearAsync(): Promise<void> {
        this.state.model = new WorkOrdersToolbarModel();

        await this.processOnChange(true);
    }

    private async onGetBulkPdfClickAsync(): Promise<void> {
        if (this.props.onGetBulkPdf) {
            await this.props.onGetBulkPdf(this.state.model);
        }
    }

    private async addWorkOrderAsync() {
        await this.props.addWorkOrder();
    }

    public render(): React.ReactNode {
        return (
            <ToolbarContainer className={styles.workOrdersToolbar}>
                <ToolbarRow justify={JustifyContent.SpaceBetween}>
                    <Form inline>
                        <WorkOrderSearchInput ref={this._searchInputRef}
                                              readonly={this.readonly}
                                              width={"40ch"}
                                              value={this.state.model.search ?? ""}
                                              label={Localizer.tasksToolbarSearch}
                                              onChange={this.onSearchChangeAsync}
                        />

                        <Dropdown multiple small noValidate noSubtext noWrap noFilter
                                  label={Localizer.tasksPageTaskStatus}
                                  minWidth="180px"
                                  align={DropdownAlign.Left}
                                  orderBy={DropdownOrderBy.None}
                                  items={EnumProvider.getTaskStatusFilterItems()}
                                  selectedItems={this.getTaskStatusFilterSelectedItems()}
                                  disabled={(this.readonly)}
                                  onChange={(sender, _, userInteraction) => this.selectTaskStatusesAsync(sender.selectedItems, userInteraction)}
                        />

                        {
                            ((this.props.costPoolFilterEnabled) && (this.props.costPools)) &&
                            (
                                <Dropdown multiple autoGroupSelected small noValidate noSubtext noWrap noFilter
                                          label={Localizer.tasksPageManagerCostPools}
                                          minWidth="180px"
                                          align={DropdownAlign.Left}
                                          orderBy={DropdownOrderBy.None}
                                          items={this.props.costPools}
                                          selectedItems={this.state.model.managerCostPoolsFilter}
                                          disabled={(this.readonly) || (this.props.costPools.length <= 0)}
                                          onChange={(sender, _, userInteraction) => this.selectTaskCostPoolsAsync(sender.selectedItems, userInteraction)}
                                />
                            )
                        }

                        <Dropdown<User> multiple autoGroupSelected small noValidate noSubtext noWrap
                                        ref={this._managersDropdownRef}
                                        label={Localizer.genericManager}
                                        minWidth="180px"
                                        align={DropdownAlign.Left}
                                        orderBy={DropdownOrderBy.None}
                                        items={this.props.managers}
                                        selectedItems={this.state.model.managers}
                                        disabled={(this.readonly) || (this.props.managers.length <= 0)}
                                        onChange={this.onManagersChangeAsync}
                        />

                        <Dropdown<User> multiple autoGroupSelected small noValidate noSubtext noWrap
                                        ref={this._mountersDropdownRef}
                                        label={Localizer.genericMounters}
                                        minWidth="180px"
                                        align={DropdownAlign.Left}
                                        orderBy={DropdownOrderBy.None}
                                        items={this.props.mounters}
                                        selectedItems={this.state.model.mounters}
                                        disabled={(this.readonly) || (this.props.mounters.length <= 0)}
                                        onChange={this.onMountersChangeAsync}
                        />

                        <Checkbox id={styles.notAssignedCheckbox}
                                  readonly={this.readonly}
                                  label={Localizer.calendarPageCalendarDataUnassignedRow}
                                  value={this.state.model.notAssigned}
                                  onChange={this.onNotAssignedChangeAsync}
                        />

                        <Inline className={styles.dateRangeContainer}>
                            <DateInput small rentaStyle
                                       id="from"
                                       label={Localizer.tasksToolbarDate}
                                       maxDate={new Date()}
                                       value={this.state.model.from || undefined}
                                       onChange={async (date) => await this.setFromAsync(date)}
                                       readonly={(this.readonly)}
                                       append={(
                                           <Icon name="fad fa-window-close"
                                                 style={IconStyle.Regular}
                                                 size={IconSize.Normal}
                                                 className={styles.clearDate}
                                                 tooltip={Localizer.genericClear}
                                                 onClick={() => this.setFromAsync(null)}
                                           />
                                       )}
                            />

                            <span className={styles.dateDelimiter}>-</span>

                            <DateInput small rentaStyle
                                       maxDate={new Date()}
                                       value={this.state.model.to || undefined}
                                       onChange={async (date) => await this.setToAsync(date)}
                                       readonly={(this.readonly)}
                                       append={(
                                           <Icon name="fad fa-window-close"
                                                 style={IconStyle.Regular}
                                                 size={IconSize.Normal}
                                                 className={styles.clearDate}
                                                 tooltip={Localizer.genericClear}
                                                 onClick={() => this.setToAsync(null)}
                                           />
                                       )}
                            />
                        </Inline>

                        <Inline className={styles.topMarginButton}>
                            <Button small
                                    label={Localizer.tasksToolbarSearch}
                                    icon={{name: "fas search"}}
                                    type={ButtonType.Orange}
                                    onClick={async () => await this.processOnChange(true)}
                                    disabled={(this.readonly)}
                            />

                            <Button small
                                    title={Localizer.tasksToolbarClearFilters}
                                    icon={{name: "far history", size: IconSize.Large}}
                                    type={ButtonType.Info}
                                    onClick={async () => await this.clearAsync()}
                                    disabled={this.readonly}
                            />

                            <FeatureSwitch flagName={FeatureFlags.WorkReportWithComments}>
                                <Button small
                                        title={Localizer.workReportWithCommentsActionLabel}
                                        icon={{name: "far file-pdf", size: IconSize.Large}}
                                        type={ButtonType.Orange}
                                        onClick={async () => await this.onGetBulkPdfClickAsync()}
                                        disabled={this.readonly}
                                />
                            </FeatureSwitch>
                        </Inline>

                    </Form>
                </ToolbarRow>

                <ToolbarRow>
                    <ToolbarButton id="addNewWorkOrderButton"
                                   icon={{name: "plus", size: IconSize.Large}}
                                   label={Localizer.workOrdersPanelNewWorkOrder}
                                   type={ButtonType.Orange}
                                   onClick={() => this.addWorkOrderAsync()}
                                   disabled={this.readonly}
                    />
                </ToolbarRow>
            </ToolbarContainer>
        );
    }
}