import React from "react";
import {BaseComponent} from "@renta-apps/athenaeum-react-common";
import {Accordion, TextAreaWidget} from "@renta-apps/athenaeum-react-components";
import WorkOrderModel from "@/models/server/WorkOrderModel";
import WorkOrderEquipment from "@/models/server/WorkOrderEquipment";
import WorkOrderExtraCharge from "@/models/server/WorkOrderExtraCharge";
import {Utility} from "@renta-apps/athenaeum-toolkit";
import FeatureFlags from "@/helpers/FeatureFlags";
import WorkOrderRentalItemModel from "@/models/server/WorkOrderRentalItemModel";
import {FeatureSwitch} from "@/components/FeatureSwitch/FeatureSwitch";
import TransformProvider from "@/providers/TransformProvider";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "@/localization/Localizer";
import {CatalogType, ProductUnit, RentalItemActionType} from "@/models/Enums";

import styles from "./WorkOrderDetails.module.scss";

class RentalItemDetailView {
    externalId: string | null = null;
    actionType: RentalItemActionType = RentalItemActionType.Leave;
    name: string = "";
    unit: ProductUnit | null = null;
    amount: number = 1;
    rentDate: Date = new Date();
    comment: string | null = null;
}

interface IWorkOrderDetailsProps {
    workOrder: WorkOrderModel;
    equipments: WorkOrderEquipment[] | null;
    extraCharges: WorkOrderExtraCharge[] | null;
    rentalEquipments: WorkOrderRentalItemModel[] | null;
}

interface IWorkOrderDetailsState {
}

export default class WorkOrderDetails extends BaseComponent<IWorkOrderDetailsProps, IWorkOrderDetailsState> {

    state: IWorkOrderDetailsState = {};

    private get workOrder(): WorkOrderModel  {
        return this.props.workOrder;
    }

    private get equipments(): WorkOrderEquipment[] | null {
        const equipments : WorkOrderEquipment[] | null = this.props.equipments ?? this.workOrder.equipments;
        return equipments!.filter(item => item.product?.category?.catalogType !== CatalogType.RentalMassProduct);
    }

    private get extraCharges(): WorkOrderExtraCharge[] | null {
        return this.props.extraCharges ?? this.workOrder.extraCharges;
    }

    private get rentalEquipments(): RentalItemDetailView[] {
        const detailViews: RentalItemDetailView[] = [];

        const rentalEquipmentsSource : WorkOrderRentalItemModel[] | null = this.props.rentalEquipments ?? this.workOrder.rentalEquipments ?? [];

        const rentalEquipments: WorkOrderRentalItemModel[] = rentalEquipmentsSource.slice();
        rentalEquipments.forEach((equipment: WorkOrderRentalItemModel) => {
            const individualRentalItem: RentalItemDetailView = {
                externalId: equipment.rentalItemExternalId,
                name: equipment.rentalItemName ?? "-",
                comment: equipment.comment,
                rentDate: equipment.rentDate,
                actionType: equipment.actionType,

                // Not valid for individual rental equipment
                amount: 1,
                unit: null,
            }

            detailViews.push(individualRentalItem)
        });
        
        const equipments : WorkOrderEquipment[] = this.props.equipments ?? this.workOrder.equipments ?? [];
        const massRentalEquipments : WorkOrderEquipment[] = equipments
            .filter(item => item.product?.category?.catalogType === CatalogType.RentalMassProduct);

        massRentalEquipments.forEach((equipment : WorkOrderEquipment) => {
            const massProductRentalItem: RentalItemDetailView = {
                name: equipment.name ?? "-",
                comment: equipment.description,
                rentDate: equipment.rentDate,
                actionType: equipment.actionType,
                amount: equipment.amount,
                unit: equipment.unit,
                
                // Not valid for mass rental equipment
                externalId: null,
            }

            detailViews.push(massProductRentalItem)
        });
        
        return detailViews;
    }

    private get displayAssignedMountersTable(): boolean {
        return (this.workOrder.mounters && this.workOrder.mounters.length > 0);
    }

    private get displayEquipmentsTable(): boolean {
        return (this.equipments != null && this.equipments.length > 0);
    }

    private get displaySalaryHoursTable(): boolean {
        return (this.workOrder.userSalaryHours != null && this.workOrder.userSalaryHours.length > 0);
    }

    private get anyAlarmJobs(): boolean {
        return (this.workOrder.userSalaryHours != null && this.workOrder.userSalaryHours.some(hour => hour.isAlarmJob));
    }

    private get displayExtraChargesTable(): boolean {
        return (this.extraCharges != null && this.extraCharges.length > 0);
    }

    private get displayRentalEquipmetsTable(): boolean {
        return (this.rentalEquipments != null && this.rentalEquipments.length > 0);
    }

    private get displayDistancesTable(): boolean {
        return (this.workOrder.distances != null && this.workOrder.distances.length > 0);
    }

    public render(): React.ReactNode {
        return (
            <div className={styles.workOrderDetails}>
                <TextAreaWidget wide readonly keepFocusOnEnter
                                 id="WorkOrderJobSummary"
                                 className={styles.jobSummary}
                                 label={Localizer.rentaTasksWorkOrderDetailsJobSummary}
                                 value={this.workOrder.jobSummary ?? "-"}
                />

                <Accordion header={Localizer.rentaTasksWorkOrderDetailsAssignedMounters}
                           expanded={true}
                           autoCollapse={false}
                >
                    {
                        (
                            (this.displayAssignedMountersTable) ?
                                (
                                    <div>
                                        <table className={this.css(styles.distancesTable, "table table-striped table-borderless")}>
                                            <thead>
                                                <tr>
                                                    <th>{Localizer.genericName}</th>
                                                    <th>{Localizer.rentaTasksWorkOrderDetailsContactInfo}</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                            {
                                                this.workOrder.mounters.map((mounter, index) => {

                                                    const contactInfo = (mounter.phone && mounter.phone.length == 0)
                                                        ? mounter.phone
                                                        : mounter.email;

                                                    return (
                                                        <tr key={mounter.id + index} className={styles.content}>
                                                            <td>{TransformProvider.toString(mounter)}</td>
                                                            <td>{contactInfo}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                )
                                :
                                (
                                    <span>{Localizer.rentaTasksWorkOrderDetailsNoAssignedMounters}</span>
                                )
                        )
                    }
                </Accordion>

                <Accordion header={Localizer.rentaTasksWorkOrderDetailsEquipments}
                           expanded={true}
                           autoCollapse={false}
                >
                    {
                        (
                            (this.displayEquipmentsTable) ?
                                (
                                    <div>
                                    {
                                        this.equipments!.map((equipment, index) => {
                                            return (
                                                <div key={equipment.id + index}>
                                                    <h6>{equipment.product?.name ?? equipment.name}</h6>

                                                    <table className={this.css(styles.table, "table table-striped")}>
                                                        <tbody>
                                                            <tr>
                                                                <th>{Localizer.genericUnit}</th>
                                                                <td>{(equipment.customUnit)
                                                                        ? equipment.customUnit
                                                                        : (equipment.unit != null)
                                                                            ? EnumProvider.getProductUnitText(equipment.unit)
                                                                            : (equipment.product?.unit != null) 
                                                                                ? EnumProvider.getProductUnitText(equipment.product.unit)
                                                                                : null}
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <th>{Localizer.genericAmount}</th>
                                                                <td>{equipment.amount}</td>
                                                            </tr>

                                                            <tr>
                                                                <th>{Localizer.genericDescription}</th>
                                                                <td>{equipment.description}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )
                                        })
                                    }
                                    </div>
                                )
                                :
                                (
                                    <span>{Localizer.rentaTasksWorkOrderDetailsNoEquipments}</span>
                                )
                        )
                    }
                </Accordion>

                <Accordion header={Localizer.genericWorkingHours}
                           expanded={true}
                           autoCollapse={false}
                >
                    {
                        (
                            (this.displaySalaryHoursTable) ?
                                (
                                    <div>
                                    {
                                        this.workOrder.userSalaryHours!.map((salaryHour, index) => {
                                            return (
                                                <div key={salaryHour.id + index}>
                                                    <h6>{TransformProvider.toString(salaryHour.user)}</h6>

                                                    <table className={this.css(styles.table, "table table-striped")}>
                                                        <tbody>
                                                            <tr>
                                                                <th>{Localizer.genericDate}</th>
                                                                <td>{Utility.format(salaryHour.day.toISODateString(), "D")}</td>
                                                            </tr>

                                                            <tr>
                                                                <th>{Localizer.rentaTasksWorkOrderDetailsSalaryHoursTableNormalHours}</th>
                                                                <td>{salaryHour.normalHours}</td>
                                                            </tr>

                                                            <tr>
                                                                <th>{Localizer.rentaTasksWorkOrderDetailsSalaryHoursTableOvertime50}</th>
                                                                <td>{salaryHour.overtime50Hours}</td>
                                                            </tr>

                                                            <tr>
                                                                <th>{Localizer.rentaTasksWorkOrderDetailsSalaryHoursTableOvertime100}</th>
                                                                <td>{salaryHour.overtime100Hours}</td>
                                                            </tr>

                                                            {
                                                                (this.anyAlarmJobs) &&
                                                                (
                                                                    <FeatureSwitch flagName={FeatureFlags.AlarmJobs}>
                                                                        <tr>
                                                                            <th>{Localizer.genericAlarmJob}</th>
                                                                            <td>{(salaryHour.isAlarmJob) ? "✓" : "-"}</td>
                                                                        </tr>
                                                                    </FeatureSwitch>
                                                                )
                                                            }

                                                            <FeatureSwitch flagName={FeatureFlags.UserSalaryHoursComment}>
                                                                <tr>
                                                                    <th>{Localizer.genericComment}</th>
                                                                    <td>{(salaryHour.comment == null || salaryHour.comment.trim() === '') ? "-" : salaryHour.comment}
                                                                    </td>
                                                                </tr>
                                                            </FeatureSwitch>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )
                                        })
                                    }
                                    </div>
                                )
                                :
                                (
                                    <span>{Localizer.rentaTasksWorkOrderDetailsNoSalaryHours}</span>
                                )
                        )
                    }
                </Accordion>

                <FeatureSwitch flagName={FeatureFlags.ExtraCharge}>
                    <Accordion header={Localizer.rentaTasksWorkOrderDetailsExtraCharges}
                               expanded={true}
                               autoCollapse={false}
                    >
                        {
                            (
                                (this.displayExtraChargesTable) ?
                                    (
                                        <div className={styles.approveWorkOrderModal}>
                                            {
                                                this.extraCharges!.map((extraCharge, index) => {
                                                    return (
                                                        <div key={extraCharge.id + index}>
                                                            <h6>{extraCharge.extraChargeType!.name}</h6>

                                                            <table className={this.css(styles.table, "table table-striped")}>
                                                                <tbody>
                                                                <tr>
                                                                    <th>{Localizer.genericUnit}</th>
                                                                    <td>{EnumProvider.getExtraChargeTypeUnitText(extraCharge.extraChargeType!.unit)}</td>
                                                                </tr>

                                                                <tr>
                                                                    <th>{Localizer.genericAmount}</th>
                                                                    <td>{extraCharge.amount}</td>
                                                                </tr>

                                                                <tr>
                                                                    <th>{Localizer.genericDate}</th>
                                                                    <td>{Utility.format(extraCharge.extraChargeDate.toISODateString(), "D")}</td>
                                                                </tr>

                                                                <tr>
                                                                    <th>{Localizer.genericDescription}</th>
                                                                    <td>{extraCharge.description}</td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                    :
                                    (
                                        <span>{Localizer.rentaTasksWorkOrderDetailsNoExtraCharges}</span>
                                    )
                            )
                        }
                    </Accordion>
                </FeatureSwitch>

                <FeatureSwitch flagName={FeatureFlags.RentalEquipment}>
                    <Accordion header={Localizer.rentalEquipmentRentalEquipment}
                               expanded={true}
                               autoCollapse={false}
                    >
                        {
                            (
                                (this.displayRentalEquipmetsTable) ?
                                    (
                                        <div id="rentalEquipmentAccordion">
                                            {
                                                this.rentalEquipments!.map((rentalEquipment, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <h6>{rentalEquipment.name}</h6>

                                                            <table className={this.css(styles.table, "table table-striped")}>
                                                                <tbody>
                                                                {
                                                                    (!!rentalEquipment.unit) &&
                                                                    <tr>
                                                                        <th>{Localizer.genericUnit}</th>
                                                                        <td>{EnumProvider.getProductUnitText(rentalEquipment.unit)}</td>
                                                                    </tr>
                                                                }

                                                                {
                                                                    (!!rentalEquipment.externalId) &&
                                                                    <tr>
                                                                        <th>{Localizer.genericExternalId}</th>
                                                                        <td>{rentalEquipment.externalId}</td>
                                                                    </tr>
                                                                }

                                                                <tr>
                                                                    <th>{Localizer.genericAmount}</th>
                                                                    <td>{rentalEquipment.amount}</td>
                                                                </tr>

                                                                <tr>
                                                                    <th>{Localizer.genericDate}</th>
                                                                    <td>{Utility.format(rentalEquipment.rentDate!.toISODateString(), "D")}</td>
                                                                </tr>

                                                                <tr>
                                                                    <th>{Localizer.rentalEquipmentActionType}</th>
                                                                    <td>{EnumProvider.getRentalItemActionTypeText(rentalEquipment.actionType)}</td>
                                                                </tr>

                                                                {
                                                                    (!!rentalEquipment.comment) &&
                                                                    <tr>
                                                                        <th>{Localizer.genericDescription}</th>
                                                                        <td>{rentalEquipment.comment}</td>
                                                                    </tr>
                                                                }

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                    :
                                    (
                                        <span>{Localizer.rentaTasksWorkOrderDetailsNoExtraCharges}</span>
                                    )
                            )
                        }
                    </Accordion>
                </FeatureSwitch>

                <Accordion header={Localizer.rentaTasksWorkOrderDetailsDistances}
                           expanded={true}
                           autoCollapse={false}
                >
                    {
                        (
                            (this.displayDistancesTable) ?
                                (
                                    <div className={styles.approveWorkOrderModal}>
                                        <table className={this.css(styles.distancesTable, "table table-striped table-borderless")}>
                                            <thead>
                                                <tr>
                                                    <th>{Localizer.genericDate}</th>
                                                    <th>{Localizer.rentaTasksWorkOrderDetailsMileagesTableVehiclesAmount}</th>
                                                    <th>{Localizer.rentaTasksWorkOrderDetailsMileagesTableTotalMileages}</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                            {
                                                this.workOrder.distances?.map((distance, index) => {
                                                    return (
                                                        <tr key={distance.id + index} className={styles.content}>
                                                            <td>{Utility.format(distance.day.toISODateString(), "D")}</td>
                                                            <td>{distance.vehicles}</td>
                                                            <td>{distance.value}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            </tbody>
                                         </table>
                                    </div>
                                )
                                :
                                (
                                    <span>{Localizer.rentaTasksWorkOrderDetailsNoDistances}</span>
                                )
                        )
                    }
                </Accordion>
            </div>
        );
    }
}